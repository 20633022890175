<template>
  <BaseContainer fluid>
    <CourseTopicHeader :course-topic-id="courseTopicId" />
    <CourseList :params="params" class="mt-6" />
    <CourseTopicSlider class="my-6" />
  </BaseContainer>
</template>

<script>
import CourseTopicSlider from "@/modules/user-course-uc/components/CourseTopicSlider";
import CourseTopicHeader from "./partials/CourseTopicHeader";
import { useRoute } from "vue-router";
import { computed, reactive, watch } from "vue";
import CourseList from "@/modules/user-course-uc/components/CourseList";

export default {
  components: { CourseList, CourseTopicHeader, CourseTopicSlider },
  setup() {
    const route = useRoute();

    const params = reactive({});

    const courseTopicId = computed(() => {
      return route.query.courseTopicId;
    });

    const search = computed(() => {
      return route.query.search;
    });

    watch([courseTopicId, search], () => {
      const localParams = {};

      if (courseTopicId.value) localParams.courseTopicIds = [courseTopicId.value];
      if (search.value) localParams.search = search.value;

      params.value = localParams;
    });

    return {
      courseTopicId,
      params,
    };
  },
};
</script>
