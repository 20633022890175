<template>
  <Dropdown
    v-model="values.courseTopicId"
    :options="topics"
    :placeholder="$t('uc.browseHeader.topic')"
    optionLabel="displayName"
    optionValue="id"
    :showClear="true"
    :loading="isLoading"
  >
    <template #option="slotProps">
      <div class="flex">
        <div class="course-topic-select__image">
          <img v-if="slotProps.option.iconUrl" :src="slotProps.option.iconUrl" alt="Icon" />
        </div>
        <div class="pl-2">
          {{ slotProps.option.displayName }} ({{ slotProps.option.coursesCount }})
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import { ref } from "@vue/reactivity";
import useApi from "@use/useApi";
import Dropdown from "primevue/dropdown";
import { reactive, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    Dropdown,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const values = reactive({
      courseTopicId: parseInt(route.query.courseTopicId, 10),
    });

    const topics = ref([]);

    const { docs, isLoading } = useApi({
      module: "uc",
      method: "listCourseTopics",
      onMount: true,
      params: {
        onlyWithCourses: true,
        withCourseCount: true,
      },
      onSuccess: () => {
        topics.value = docs.value.data;
      },
    });

    const pushToSearch = async () => {
      const query = {};

      if (values.courseTopicId) {
        query.courseTopicId = values.courseTopicId;
      }

      if (route.query.search) {
        query.search = route.query.search;
      }

      if (route.name !== "Courses") {
        return; // will redirect back on page switch otherwise
      }

      try {
        await router.push({
          name: "Courses",
          query: query,
        });
      } catch {
        return;
      }
    };

    watch(
      () => values.courseTopicId,
      () => {
        pushToSearch();
      }
    );

    watch(
      () => route.query.courseTopicId,
      () => {
        values.courseTopicId = parseInt(route.query.courseTopicId, 10);
      }
    );

    return {
      topics,
      isLoading,
      values,
      pushToSearch,
    };
  },
};
</script>

<style lang="scss" scoped>
.course-topic-select {
  &__image {
    width: 16px;

    img {
      width: 100%;
    }
  }
}
</style>
