<template>
  <BaseCol class="course-list-col flex">
    <BaseCard class="px-3 m-3 w-full">
      <BaseRow>
        <BaseCol cols="12">
          <CourseThumbnail :course="course" />
        </BaseCol>
        <BaseCol cols="12">
          <BaseHeadline size="6" no-margin>{{ course.title }}</BaseHeadline>
        </BaseCol>
        <BaseCol cols="12">
          <CourseTopics :course-topics="course.courseTopics" />
        </BaseCol>
        <BaseCol cols="12">
          <BaseRow class="flex justify-content-between mt-1" :no-gutter="true">
            <BaseCol cols="shrink" class="flex">
              <div style="color: var(--gray-500)">
                {{ getTeacherName(course.teacher) }}
              </div>
            </BaseCol>
            <BaseCol cols="auto">
              <div class="text-primary">
                {{ getPriceLabel(course.price) }}
              </div>
            </BaseCol>
          </BaseRow>
        </BaseCol>
      </BaseRow>
      <BaseRow class="flex justify-content-between mt-3" :no-gutter="true">
        <BaseCol cols="shrink" class="flex">
          <ShowCourseButton :course-id="course.id" />
        </BaseCol>
        <BaseCol cols="auto">
          <AddCartItemButton
            v-if="isAuthenticated"
            :course-id="course.id"
            :has-added="course.inCart"
            :is-owned="!!course.courseOwnership"
          />
          <BaseButton v-else label="uc.addToCart.label" @click="pushToLogin" />
        </BaseCol>
      </BaseRow>
    </BaseCard>
  </BaseCol>
</template>

<script>
import CourseTopics from "@/modules/haw-components-hac/Base/CourseTopics";
import CourseThumbnail from "@/modules/user-course-uc/components/CourseThumbnail/CourseThumbnail";
import AddCartItemButton from "@/modules/user-course-uc/components/AddCartItemButton";
import ShowCourseButton from "./ShowCourseButton";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { useStore } from "vuex";
import { getTeacherName } from "@/modules/user-course-uc/helper/GetTeacherName";
import { getPriceLabel } from "@/modules/user-course-uc/helper/GetPriceLabel";

export default {
  components: {
    ShowCourseButton,
    AddCartItemButton,
    CourseThumbnail,
    CourseTopics,
  },

  props: {
    course: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const router = useRouter();
    const store = useStore();

    const isAuthenticated = computed(() => {
      return store.getters["usm/auth/isAuthenticated"];
    });

    const pushToLogin = async () => {
      try {
        await router.push({
          name: "Login",
        });
      } catch {
        return;
      }
    };

    return {
      pushToLogin,
      isAuthenticated,
      getTeacherName,
      getPriceLabel,
    };
  },
};
</script>

<style lang="scss" scoped>
.course-list-col {
  min-width: 100%;
  max-width: 100%;

  @media only screen and (min-width: 1000px) {
    min-width: 50%;
    max-width: 50%;
  }

  @media only screen and (min-width: 1600px) {
    min-width: 33%;
    max-width: 33%;
  }

  @media only screen and (min-width: 1800px) {
    min-width: 25%;
    max-width: 25%;
  }
}
</style>
