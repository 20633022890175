<template>
  <BaseButton @click="pushToCourse" icon="pi pi-eye" outlined />
</template>

<script>
export default {
  name: "ShowCourseButton",
  props: {
    courseId: {
      type: Number,
      required: true,
      default: null,
    },
  },
  methods: {
    pushToCourse() {
      try {
        this.$router.push({
          name: "CoursesShow",
          params: {
            courseId: this.courseId,
          },
        });
      } catch {
        return;
      }
    },
  },
};
</script>
