import { useI18n } from "vue-i18n";

export const getPriceLabel = (price) => {
  const { t } = useI18n();

  return price
    ? t("uc.courses.list.price", {
        price,
      })
    : t("uc.courses.list.free");
};
