<template>
  <div>
    <BaseRow v-if="isLoading" cols="12">
      <BaseCol cols="12" class="flex justify-content-center py-3">
        <BaseProgressSpinner />
      </BaseCol>
    </BaseRow>

    <BaseRow v-else-if="meta.total === 0">
      <BaseCol cols="12" class="text-center pb-6 pt-8">
        <BaseParagraph>{{ $t("uc.courses.list.noData") }}</BaseParagraph>
      </BaseCol>
    </BaseRow>

    <DataView
      v-bind="$attrs"
      layout="grid"
      :value="courses"
      :paginator="true"
      :lazy="true"
      :rows="12"
      :total-records="meta.total"
      @page="onPage($event)"
      v-else
    >
      <template #grid="slotProps">
        <CourseListColumn :course="slotProps.data" />
      </template>
    </DataView>
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { reactive, ref, watch } from "vue";
import DataView from "primevue/dataview";
import CourseListColumn from "./partials/CourseListColumn";

export default {
  name: "CourseList",

  props: {
    params: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  components: {
    CourseListColumn,
    DataView,
  },

  setup(props) {
    const courses = ref([]);
    const meta = ref({});

    const params = reactive(props.params);

    const { docs, callApi, isLoading } = useApi({
      module: "uc",
      method: "listCourses",
      onMount: true,
      params: {
        page: 1,
        itemsPerPage: 12,
        ...props.params,
      },
      onSuccess: () => {
        courses.value = docs.value.data;
        meta.value = docs.value.meta;
      },
    });

    watch(params, () => {
      callApi(params.value);
    });

    const onPage = (event) => {
      callApi({ page: event.page + 1, ...params.value });
    };

    return {
      courses,
      onPage,
      meta,
      isLoading,
    };
  },
};
</script>
