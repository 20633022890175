<template>
  <BaseButton
    class="add-to-cart-button"
    :label="label"
    :icon="icon"
    @click="callApi"
    :loading="isLoading"
    :disabled="added || owned"
  />
</template>

<script>
import useApi from "@use/useApi";
import { computed, ref } from "vue";

export default {
  name: "AddCartItemButton",
  props: {
    courseId: {
      type: Number,
      required: false,
      default: null,
    },
    hasAdded: {
      type: Boolean,
      required: false,
      default: false,
    },
    isOwned: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const added = ref(props.hasAdded);
    const owned = ref(props.isOwned);

    const { isLoading, callApi } = useApi({
      module: "uc",
      method: "cartAddCourse",
      params: {
        courseId: props.courseId,
      },
      onSuccess: () => {
        added.value = true;
      },
    });

    const label = computed(() => {
      if (owned.value) {
        return "uc.addToCart.owned";
      }

      return added.value ? "uc.addToCart.added" : "uc.addToCart.label";
    });

    const icon = computed(() => {
      if (added.value || owned.value) {
        return "pi pi-check";
      }

      return null;
    });

    return {
      isLoading,
      callApi,
      added,
      owned,
      label,
      icon,
    };
  },
};
</script>

<style lang="scss">
.add-to-cart-button {
  .p-button-icon {
    padding-right: 0.5em;
  }
}
</style>
