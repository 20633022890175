<template>
  <div class="thumb-wrapper">
    <img
      v-if="course.thumbnailUrl"
      :src="course.thumbnailUrl"
      class="w-full h-auto"
      :alt="$t('uc.courses.list.imgAlt', { title: course.title })"
    />
    <img
      v-else
      src="../../assets/course/placeholder-image.jpg"
      :alt="$t('uc.courses.list.placeholderImgAlt')"
      class="w-full h-auto"
    />
  </div>
</template>

<script>
export default {
  name: "CourseThumbnail",
  props: {
    course: {
      type: Object,
      required: true,
      default: () => ({
        title: null,
        thumbnailUrl: null,
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.thumb-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 58.787878787878788%;
  overflow: hidden;
  border-radius: 15px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 101%;
    height: auto;
  }
}
</style>
