<template>
  <div class="course-topic-slider max-w-1024">
    <BaseHeadline size="4" fake="5">
      {{ $t("uc.courses.suggestedTopics") }}
    </BaseHeadline>
    <div v-if="isLoading" class="flex justify-content-center align-items-center">
      <BaseProgressSpinner size="40" />
    </div>
    <BaseRow v-else>
      <BaseCol
        v-for="(courseTopic, index) in courseTopics"
        :key="index"
        class="course-topic-slider__col"
        cols="12"
        md="6"
        lg="3"
      >
        <div
          @click="goToCourseTopic(courseTopic.id)"
          class="course-topic-slider__box py-3 px-1 text-center"
        >
          <BaseParagraph :noMargin="true"
            >{{ courseTopic.displayName }} ({{ courseTopic.coursesCount }})</BaseParagraph
          >
        </div>
      </BaseCol>
    </BaseRow>
  </div>
</template>

<script>
import useApi from "@use/useApi";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "CourseTopicSlider",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const courseTopics = ref([]);

    const { docs, isLoading } = useApi({
      module: "uc",
      method: "listCourseTopics",
      onMount: true,
      params: {
        onlyWithCourses: true,
        withCourseCount: true,
      },
      onSuccess: () => {
        courseTopics.value = docs.value.data;
      },
    });

    const goToCourseTopic = async (id) => {
      const query = { courseTopicId: id };

      if (route.query.search) {
        query.search = route.query.search;
      }

      try {
        await router.push({
          name: "Courses",
          query: query,
        });
      } catch (error) {
        return;
      }
    };

    return {
      courseTopics,
      isLoading,
      goToCourseTopic,
    };
  },
};
</script>

<style scoped lang="scss">
.course-topic-slider {
  .course-topic-slider__box {
    border: 1px solid #aaa;
    cursor: pointer;
  }
}
</style>
